<template>
  <validation-observer
    ref="createTxnForm"
    #default="{ invalid }"
  >
    <b-form
      class="create-order-form"
      @submit.prevent="$emit('dynamic-emits', 'submit')"
    >
      <validation-provider
        #default="{ errors }"
        name="Order ID"
        vid="orderId"
        rules="required"
      >
        <k-form-group
          class="mt-2"
          :form-invalid-content="errors[0]"
          :state="errors.length > 0 ? false : null"
          label-for="order-id"
        >
          <template #label>
            Order ID <span
              v-if="!componentContent.isEdit"
              class="text-danger"
            >*</span>
          </template>
          <k-form-input
            v-model="componentContent.orderModel.orderId"
            type="text"
            name="order-id"
            placeholder="Order ID"
            :state="errors.length > 0 ? false : null"
            autocomplete="off"
            :disabled="componentContent.isEdit"
          />
        </k-form-group>
      </validation-provider>
      <validation-provider
        #default="{ errors }"
        name="Order Date"
        vid="orderDate"
        rules="required"
      >
        <k-form-group
          class="mt-2"
          :form-invalid-content="errors[0]"
          :state="errors.length > 0 ? false : null"
          label-for="order-date"
        >
          <template #label>
            Order Date <span
              v-if="!componentContent.isEdit"
              class="text-danger"
            >*</span>
          </template>

          <k-form-input
            v-model="componentContent.orderModel.orderDate"
            type="text"
            name="order-date"
            placeholder="Order Date"
            :state="errors.length > 0 ? false : null"
            autocomplete="off"
            :disabled="componentContent.isEdit"
          />
        </k-form-group>
      </validation-provider>
      <validation-provider
        #default="{ errors }"
        name="Brand Name"
        vid="brandName"
        rules="required"
      >
        <k-form-group
          class="mt-2"
          :form-invalid-content="errors[0]"
          :state="errors.length > 0 ? false : null"
          label-for="brand-name"
        >
          <template #label>
            Brand Name <span
              v-if="!componentContent.isEdit"
              class="text-danger"
            >*</span>
          </template>
          <k-form-input
            v-model="componentContent.orderModel.brandName"
            type="text"
            name="brand-name"
            placeholder="Brand Name"
            :state="errors.length > 0 ? false : null"
            autocomplete="off"
            :disabled="componentContent.isEdit"
          />
        </k-form-group>
      </validation-provider>
      <validation-provider
        #default="{ errors }"
        name="Retailer Name"
        vid="retailerName"
        rules="required"
      >
        <k-form-group
          class="mt-2"
          :form-invalid-content="errors[0]"
          :state="errors.length > 0 ? false : null"
          label-for="retailer-name"
        >
          <template #label>
            Retailer Name <span
              v-if="!componentContent.isEdit"
              class="text-danger"
            >*</span>
          </template>
          <k-form-input
            v-model="componentContent.orderModel.retailerName"
            type="text"
            name="retailer-name"
            placeholder="Retailer Name"
            :state="errors.length > 0 ? false : null"
            autocomplete="off"
            :disabled="componentContent.isEdit"
          />
        </k-form-group>
      </validation-provider>
      <validation-provider
        #default="{ errors }"
        name="Order Status"
        vid="orderStatus"
        rules="required"
      >
        <k-form-group
          class="mt-2"
          :form-invalid-content="errors[0]"
          :state="errors.length > 0 ? false : null"
          label-for="order-status"
        >
          <template #label>
            Order Status <span class="text-danger">*</span>
          </template>
          <b-dropdown
            class="w-100 mt-1 k-search-dropdown dropdown-custom-class"
            variant="none"
            toggle-class="drop-caret-icon"
            :text="TRANSACTION_STATUS[componentContent.orderModel.orderStatus] || 'Select a status'"
          >
            <b-dropdown-item
              v-for="option in Object.keys(TRANSACTION_STATUS)"
              :key="option"
              :active="componentContent.orderModel.orderStatus === option"
              @click="componentContent.orderModel.orderStatus = option"
            >
              {{ TRANSACTION_STATUS[option] }}
            </b-dropdown-item>
          </b-dropdown>
        </k-form-group>
      </validation-provider>
      <validation-provider
        #default="{ errors }"
        name="Total Price"
        vid="totalPrice"
        rules="required"
      >
        <k-form-group
          class="mt-2"
          :form-invalid-content="errors[0]"
          :state="errors.length > 0 ? false : null"
          label-for="total-price"
        >
          <template #label>
            Total Price <span class="text-danger">*</span>
          </template>
          <k-form-input
            v-model="componentContent.orderModel.totalPrice"
            type="text"
            name="total-price"
            placeholder="Total Price"
            :state="errors.length > 0 ? false : null"
            autocomplete="off"
          />
        </k-form-group>
      </validation-provider>
      <validation-provider
        #default="{ errors }"
        name="Total Units"
        vid="totalUnits"
        rules="required"
      >
        <k-form-group
          class="mt-2"
          :form-invalid-content="errors[0]"
          :state="errors.length > 0 ? false : null"
          label-for="total-units"
        >
          <template #label>
            Total Units <span class="text-danger">*</span>
          </template>
          <k-form-input
            v-model="componentContent.orderModel.totalUnits"
            type="text"
            name="total-units"
            placeholder="Total Units"
            :state="errors.length > 0 ? false : null"
            autocomplete="off"
          />
        </k-form-group>
      </validation-provider>
      <div class="d-flex flex-row">
        <k-button
          variant="info"
          type="submit"
          block
          :disabled="invalid || loading"
        >
          SAVE CHANGES
          <feather-icon
            v-if="loading"
            icon="LoaderIcon"
          />
        </k-button>
        <k-button
          class="ms-2"
          variant="outline-info"
          :disabled="loading"
          block
          @click="$emit('dynamic-emits', 'cancel')"
        >
          CANCEL
        </k-button>
      </div>
    </b-form>
  </validation-observer>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { KButton, KFormGroup, KFormInput } from '@kingpin-global/kingpin-ui'
import '@fontsource/work-sans'
import '@fontsource/inter'
import { TRANSACTION_STATUS } from '@/constants'
import { required, email } from '../../@core/utils/validations/validations'

export default {
  name: 'CreateOrder',
  components: {
    BForm,
    KButton,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    KFormGroup,
    KFormInput,
  },
  props: {
    componentContent: {
      type: Object,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      loading: false,
      TRANSACTION_STATUS,
    }
  },
  computed: {
    required() {
      return required
    },
    email() {
      return email
    },
  },
  emits: ['dynamic-emits'],
}
</script>

<style lang="scss">
@import '../../assets/scss/variables/_variables-components.scss';
@import '../../assets/scss/kingpin-style.scss';
.create-order-form {
    margin-top: 3%;
    padding: 0% $side-bar-form-padding;
}
</style>
