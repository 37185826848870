<template>
  <div>
    <div>
      <k-header-section
        header-text="Orders"
        item-label="Orders"
        :tabs-label="true"
        :total-items-count="totalItemsCount"
        :current-page-count="currentPageCount"
        @on-click-create="onSetCreateOrderForm"
      />
    </div>
    <div>
      <router-view @update-total-items="updateTotalItems" />
    </div>
  </div>
</template>

<script>
import { KHeaderSection } from '@kingpin-global/kingpin-ui'
import {
  SET_COMPONENT,
  SET_COMPONENT_CONTENT,
  SET_FORM_ACTIONS,
  SET_TITLE,
  TOGGLE_SIDEBAR,
} from '@/store/modules/sidebar.module'
import CreateOrder from './CreateOrder.vue'

export default {
  name: 'Orders',
  components: {
    KHeaderSection,
  },
  data() {
    return {
      createOrderSidebar: false,
      currentPageCount: 0,
      totalItemsCount: 0,
    }
  },
  computed: {
    componentContent() {
      return {
        orderModel: {
          orderId: '',
          orderDate: '',
          brandName: '',
          retailerName: '',
          orderStatus: '',
          totalPrice: '',
          totalUnits: '',
        },
      }
    },
  },
  methods: {
    onSetCreateOrderForm() {
      this.$store.commit(SET_TITLE, 'Create New Order')
      this.$store.commit(SET_COMPONENT, CreateOrder)
      this.$store.commit(SET_COMPONENT_CONTENT, this.componentContent)
      this.$store.commit(SET_FORM_ACTIONS, this.formActions)
      this.toggleSidebar()
    },
    toggleSidebar() {
      this.$store.commit(TOGGLE_SIDEBAR)
    },
    saveTxn() {
      // Write form submit function here ...
      this.toggleSidebar()
    },
    formActions(actionType) {
      switch (actionType) {
        case 'submit':
          this.saveTxn()
          break
        case 'cancel':
          break
        default:
          this.toggleSidebar()
      }
    },
    updateTotalItems(currentPageCount, totalItemsCount) {
      this.currentPageCount = currentPageCount
      this.totalItemsCount = totalItemsCount
    },
  },
}
</script>
